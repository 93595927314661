import React from 'react';
import { images } from '../../../constants/Images';
import {
   Link,
 } from "react-router-dom";
import { validation, i18n } from '../../../services';
import AlertBox from '../../AlertBox';
import { withTranslation } from 'react-i18next';
import config from '../../../constants/config';
import { PATH } from '../../../navigation/NavigationPath';

interface IProps {
   onSubscribe: (param: string) => void;
   newsletterSubscribing: { loading: boolean, failed: boolean }
   openCart: () => void;
}

interface IState {
   formData: { fields: { email: string }, required: string[] },
   validation: any
}

class Footer extends React.Component<IProps, IState> {
   state = {
      formData: {
         fields: {
            email: ''
         },
         required: ['email']
       },
       validation: {},
   }

   handleChange = (e: any, field: string) => {
      const { formData } = this.state;
      this.setState({ formData: { ...formData, fields: { ...formData.fields, [field]: e.target.value } } })
    }

    onSubscribe = () => {
      const { formData } = this.state;
      console.log('formData', formData);
      const validate = validation.isValidate(formData)
      console.log('validate', validate);
      
      this.setState({ validation: validate })
      
      if (!Object.keys(validate).length ) {
         this.props.onSubscribe(formData.fields.email)
      }
    }

    renderItem = () => {
       const { openCart } = this.props
      const footerArr: { title: string, links: any[] }[] = [
           { 
            title: i18n.t(`Site_NAME`),
              links: [
                 { name: i18n.t(`footer.compony.about`), link: '/about-us' },
                 { name: i18n.t(`footer.compony.privacy`), link: '/privacy-policy' },
                 { name: i18n.t(`footer.compony.terms`), link: '/terms-and-conditions' }
              ]   
           },
           { 
            title: i18n.t(`footer.tires`),
              links: [
                 { name: i18n.t(`footer.tires.shop_by_size`), link: PATH.SEARCH_BY_SIZE },
                 { name: i18n.t(`footer.tires.shop_by_vehicle`), link: PATH.SEARCH_BY_BRAND },
                 { name: i18n.t(`footer.tires.track`), action: openCart  }
              ]   
           },
           { 
            title: i18n.t(`footer.support`),
              links: [
                 { name: i18n.t(`footer.compony.contact`), link: PATH.CONTACT_US },
                 { name: i18n.t(`footer.support.faq`), link: PATH.FAQ },
              ]   
           }
      ]

      return footerArr.map(( row, index ) => {
         return (

            <div className="col-lg-3 col-sm-4" key={index}>
               <div className="widget widget-links widget-light pb-2 mb-4">
               <h3 className="widget-title text-light">{row.title}</h3>
                  <ul className="widget-list">
                     {
                      this.renderLinks(row.links)
                     }
                   
                  </ul>
               </div>
            </div>
         )
      })
   }

   renderLinks = (links: any[]) => {
      return   links.map(( rowItem, index ) => {
         return (
            <li className="widget-list-item" key={index}>
               {
                  rowItem.action ? <a className="widget-list-link"  href="javascript:void(0);" onClick={rowItem.action}>{rowItem.name}</a> : <Link className="widget-list-link" to={rowItem.link} >{rowItem.name}</Link>
               }
            </li>
         )
      })
   }

    render() {
       const { formData } = this.state;
       const { newsletterSubscribing } = this.props;
       console.log('newsletterSubscribing', newsletterSubscribing);
       
      return (
         <footer style={{backgroundColor: '#474747', padding: '100px 0 10px', position: 'relative'}}>
            <div className="container px-5">
            <div className="row pb-2">

            {this.renderItem()}
    
   
            <div className="col-lg-3 col-sm-8">
               <div className="widget pb-2 mb-4 newsletter">
                  <h3 className="widget-title text-light">{i18n.t(`footer.newsletter.title`)}</h3>
                  <p>{i18n.t(`footer.newsletter.description`)}</p>
                  {validation.renderError('email', this.state.validation) && (
                        <AlertBox message={validation.renderError('email', this.state.validation)} show={true} type={'danger'}/>
                     )
                  }
                  <div className="input-group mb-3">
                     <input type="email" className="form-control" placeholder="Enter Email" aria-label="Enter Email" aria-describedby="basic-addon2" onChange={(value: any) => this.handleChange(value, 'email')} value={formData.fields.email}/>
                     <div className="input-group-append">
                        {
                           newsletterSubscribing.loading ? <img src={images.loader}  style={{ height: 50, width: 50, position: 'absolute', color: "#fff",right: '0px',top: '-5px'  }}/> : (
                              <button className="btn" type="button" onClick={this.onSubscribe}>Submit</button>
                           )
                        }
                     </div>
                  </div>
               </div>
            </div>
         </div>

         </div>
         <hr className="hr-light mt-md-2 pb-3" />
         <div className="container px-5">   
            <div className="d-md-flex justify-content-between">
            <div className="widget widget-links widget-light">
               <ul className="widget-list d-flex flex-wrap justify-content-center justify-content-md-start social">
              {/* <li className="widget-list-item">
                  <a className="widget-list-link font-size-ms" href="javascript:void:(0);"><img src={images.facebook} alt="facebook" /></a></li>*/}

                  <li className="widget-list-item"><a className="widget-list-link font-size-ms" href="https://x.com/Estbnh_tires?s=08" target="_blank"><img src={images.twitter}  alt="Twitter" /></a></li>
                  <li className="widget-list-item"><a className="widget-list-link font-size-ms" href="https://instagram.com/estbnh_tires?igshid=1a4kytfkbxb84" target="_blank"><img src={images.insta} alt="Instagram" /></a></li>

               </ul>
            </div>
            <div className="d-lg-flex justify-content-center align-items-center font-size-xs text-light opacity-50 text-center text-md-right">© 2020-2021 <a className="text-light" href="javascript:void:(0);" target="_blank" rel=""> &nbsp;Estbnh.com</a>, All Right Reserved</div>

         </div>
         </div>
         </footer>

      )
    }
}

export default withTranslation()<any>(Footer)